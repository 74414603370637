import React from "react";
import SocialMedia from "../../../components/socialmedia";
import LogoFooter from "../../../components/svg/logoFooter";
import Text from "../../../components/text";
import NavList from "../../../components/navList";

const Footer = () => {
  const Year = new Date().getFullYear();
  return (
    <footer className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="logo-footer mb-20">
              <LogoFooter />
            </div>
            <SocialMedia />
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-4">
                <Text type="H5" text="Company" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "2",
                      text: "About Us",
                      url: "/about-us",
                    },
                    {
                      id: "3",
                      text: "Partnership",
                      url: "/partnership",
                    },
                    {
                      id: "4",
                      text: "Support",
                      url: "/support",
                    },
                    // {
                    //   id: "4",
                    //   text: "API Documentation",
                    //   url: "https://document.payonramp.com/",
                    // },
                    {
                      id: "4",
                      text: "Talk to Us",
                      url: "/becomeAPartner",
                    },
                    {
                      id: "5",
                      text: "Licenses",
                      url: "/license",
                    },
                  ]}
                />
              </div>
              <div className="col-4">
                <Text type="H5" text="Products" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "1",
                      text: "Buy/Sell",
                      url: "/",
                    },
                  ]}
                />
              </div>
              <div className="col-4">
                <Text type="H5" text="Resources" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "1",
                      text: "Privacy Policy",
                      url: "/privacyPolicy",
                    },
                    {
                      id: "2",
                      text: "Terms and Condition",
                      url: "/termsAndConditions",
                    },
                    {
                      id: "3",
                      text: "User agreement",
                      url: "/userAgreement",
                    },
                    {
                      id: "4",
                      text: "User policy",
                      url: "/userPolicy",
                    },
                    {
                      id: "5",
                      text: "Blogs",
                      url: "https://blogs.payonramp.com/",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrapper d-flex flex-column flex-lg-row justify-content-between">
          <div>
            <span className="d-flex align-items-start">
              BLOCKFORCE INDIA PRIVATE LIMITED is a registered money service
              business and registered entity of PayOnRamp
            </span>
            <br />
            <span>Copyright &copy; {Year} PayOnRamp. All rights reserved.</span>
          </div>
          <div className="d-flex flex-column mt-20">
            <span className="mb-2">
              <b>FIU-IND Registration:</b>
            </span>
            <span>
              We have submitted our application for FIU registration, and it is
              currently being processed.
            </span>
          </div>
          <div className="d-flex flex-column w-50 col-lg-6 mt-25 mt-lg-0">
            <div className="d-flex flex-column">
              <span>Blockforce India Private Limited</span>
              <span>
                8-2-227/A & 8-2-227/A/8, Office 1, 2nd Floor, White Crow
                Building, Road No 2 Hyderabad, Telangana, India 500034
              </span>
              <span>CIN : U72900TG2021PTC148456</span>
            </div>
            <div className="d-flex flex-column mt-30">
              <span>Fin Genie Tech Private Limited</span>
              <span>
                B-1/H3 Mohan Co-Operative Industrial Area Mathura Road Delhi,
                New Delhi DL110044 India
              </span>
              <span>CIN : U72900DL2022PTC394660</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
